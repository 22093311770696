<template>
  <div class="row m-0">
    <MenuTaller />
    <loading class="col-lg-10 p-0" v-if="estadoloading" />
    <div
      class="col-lg-10 p-0"
      style="height: calc(100vh - 113px); overflow-y: scroll"
      v-else
    >
      <div>
        <div
          class="list-taller border-bottom border-right mt-2 mb-2"
          v-for="(taller, index) in talleres"
          :key="taller.id"
          v-if="[1, 2].includes(taller.estadoTaller.id)"
        >
          <p class="mb-0">
            <strong>Nombre empresa:</strong>
            {{ taller.empresa.nombre }}
          </p>
          <p class="mb-0">
            <strong>Tipo de taller:</strong>
            {{ taller.tipoTaller.nombre }}
          </p>
          <p class="mb-0">
            <strong>Número de participantes:</strong>
            {{ taller.cantPersona }}
          </p>
          <p class="mb-0">
            <strong>Formadores:</strong>
            {{
              taller.formadoruno.fullname != undefined
                ? taller.formadoruno.fullname
                : ""
            }}
            {{
              taller.formadordos.fullname != undefined
                ? ", " + taller.formadordos.fullname
                : ""
            }}
            {{
              taller.formadortres.fullname != undefined
                ? ", " + taller.formadortres.fullname
                : ""
            }}
            {{
              taller.formadorcuatro.fullname != undefined
                ? ", " + taller.formadorcuatro.fullname
                : ""
            }}
          </p>
          <p class="mb-0">
            <strong>Fecha:</strong>
            {{ taller.fecha }}
          </p>
          <p class="mb-0">
            <strong>Ciudad:</strong>
            {{ taller.ciudad.nombre }}
          </p>
          <p class="mb-0">
            <strong>Hora:</strong>
            {{ taller.hora }}
          </p>
          <p class="mb-0">
            <strong>Estado:</strong>
            <label
              class="mb-0 px-2 rounded text-white"
              :class="taller.estadoTaller.id == 1 ? 'bg-secondary' : 'bg-info'"
              >{{ taller.estadoTaller.nombre }}</label
            >
          </p>
          <div class="col-lg-12 mb-1 d-flex justify-content-end">
            <ul class="nav">
              <li
                class="nav-item border-right border-dark"
                v-if="taller.estadoTaller.id == 1"
              >
                <p
                  class="nav-link m-0 py-0 text-white bg-dark mx-1 rounded cursor-pointer"
                  @click="editar(taller.id)"
                >
                  Editar
                </p>
              </li>
              <li class="nav-item">
                <p
                  class="nav-link m-0 py-0 text-white bg-danger mx-1 rounded cursor-pointer"
                  @click="eliminar(index, taller.id)"
                >
                  Eliminar
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import loading from "@/components/Loading";
import MenuTaller from "@/components/MenuTaller";
import moment from "moment";
export default {
  name: "talleres",
  data() {
    return {};
  },
  components: {
    loading,
    MenuTaller,
  },
  methods: {
    editar(id) {
      this.$router.push({ name: "editar.taller", params: { id: id } });
    },
    eliminar(index, id) {
      this.$store.dispatch("deleteTaller", id).then((response) => {
        if (response.STATUS_CODE == 1) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Se elimino el taller correctamente.",
          });
          this.talleres.splice(index, 1);
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "Error al eliminar el taller",
          });
        }
      });
    },
  },
  computed: {
    talleres() {
      return this.$store.getters.getTalleres;
    },
    estadoloading() {
      return this.$store.getters.getEstatusLoading;
    },
  },
  mounted() {
    this.$store.dispatch("fetchTalleres");
  },
};
</script>
<style lang="css">
.error > .vs__dropdown-toggle,
.error > .display-time,
.error {
  border-color: red !important;
}
</style>
