<template>
  <div class="col-lg-2 ml-0 pl-0" style="height:calc(100vh - 113px);">
    <ul class="item-taller pl-0 mb-0">
      <li
        class="cursor-pointer mt-3 text-center py-1"
        :class="/proximos/.test($route.path)?'item-taller-activo':''"
      >
        <router-link :to="{ name: 'talleres' }">Próximo talleres</router-link>
      </li>
      <li
        class="cursor-pointer text-center py-1"
        :class="/programar/.test($route.path)?'item-taller-activo':'my-1'"
      >
        <router-link :to="{ name: 'crear.taller' }">Programar taller</router-link>
      </li>
      <li
        class="cursor-pointer text-center py-1"
        :class="/list-plantillas/.test($route.path)?'item-taller-activo':'my-1'"
      >
        <router-link :to="{ name: 'plantillas' }">Plantillas</router-link>
      </li>
      <li
        class="cursor-pointer text-center py-1"
        :class="/plantilla-taller/.test($route.path)?'item-taller-activo':'my-1'"
      >
        <router-link :to="{name: 'crear.plantilla' }">Crear nuevo taller</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "menu_taller",
};
</script>